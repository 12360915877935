<template>
  <div class="container">
    <h3 class="text-center mb-5 mt-4">Super Admin Dashboard</h3>
    <div class="d-flex flex-column justify-content-between gap-4">
      <InfoSection title="Information Change Requests" :count="pendingRequests + ' changes'" route="superadmin.requests"
        status="1" button="View Changes" />
      <InfoSection title="Form Submissions" :count="formSubmissionsCount + ' submissions'"
        route="superadmin.form.submissions" button="View Submissions" />
      <InfoSection title="Autoresponders" route="superadmin.autoresponders" button="View templates" />
      <InfoSection title="Document Mapping Screen" route="superadmin.document.mapping" button="View forms" />
      <InfoSection title="Alerts & Notifications" route="superadmin.alerts" button="Manage Alerts" />
      <InfoSection title="Bulk Email" route="superadmin.bulk.email" button="Create Distribution List" />

      <div class="d-flex justify-content-between align-items-end gap-4 w-100">
        <span class="fw-bold"> Users and Groups </span>
        <div class="d-flex gap-2">
          <router-link :to="{ name: 'superadmin.users.employers' }" class="btn btn-secondary">
            Search Employers
          </router-link>
          <router-link :to="{ name: 'superadmin.users.locals' }" class="btn btn-secondary">
            Search Locals
          </router-link>
          <a href="#" class="btn btn-secondary"> Search Super Locals </a>
          <router-link :to="{ name: 'superadmin.users.participants' }" class="btn btn-secondary">
            Search Participants
          </router-link>
        </div>
      </div>

      <InfoSection title="Contact Form" route="superadmin.contact.form" button="Contact Form" />
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted } from 'vue';
import InfoSection from '@components/superadmin/InfoSection.vue'
import axios from 'axios';

export default {
  components: {
    InfoSection
  },
  setup() {
    const pendingRequests = ref(0);
    const formSubmissionsCount = ref(0);

    const fetchData = () => {
      axios.get('api/administrator/dashboard')
        .then(response => {
          pendingRequests.value = response.data.PendingRequests;
          formSubmissionsCount.value = response.data.FormSubmissionsCount;
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    };

    onMounted(fetchData);

    return {
      pendingRequests,
      formSubmissionsCount,
    }
  },
};
</script>