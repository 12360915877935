<template>
    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <h3>Pension</h3>
          <div v-if="data.HasRecentActivityAnyPlan">
            <!-- IF has recent activity pull data from plans -->
            <div v-for="(plan, key) in hasActivityPlans" :key="key">
                <h4>{{ key }}</h4>
                <p v-if="plan.ReportingPeriod">Last Reporting Period: {{ plan.ReportingPeriod.From }} - {{ plan.ReportingPeriod.To }}</p>

                <div v-if="plan.HasActivity && plan.HasCurrentActivity">
                    <h5>{{ key }}: {{ plan.UnitType }} Worked</h5>
                    <p>{{ plan.TotalWorkUnit }} {{ plan.UnitType }}</p>
                </div>

                <div v-if="data.IsEligible && plan.LastPWHD">
                    <h5>Most Recent Pension Payment</h5>

                    <div v-if="data.RecentPayment">
                        ${{ data.RecentPayment.Net }} on {{ data.RecentPayment.CheckDate }}
                    </div>
                </div>

                <div v-if="plan.HasActivity && plan.HasCurrentActivity">
                    <h5>Most Recent Pension Payment</h5>

                    <div v-if="data.RecentPayment">
                        {{ data.RecentPayment.Net }} on {{ data.RecentPayment.CheckDate }}
                    </div>
                </div>
            </div>
          </div>
          <div v-else>
            <!-- If no current activity in any plan pull data from last Reporting period -->
            <p v-if="data.LastPeriod.Start">Last Reporting Period: {{ data.LastPeriod.Start }} - {{ data.LastPeriod.End }}</p>

            <div v-for="(plan, key) in data.LastPeriod.Plans" :key="key">
                <h5>{{ key }}: Days Worked</h5>
                <p>{{ plan.TotalWorkUnit }} days</p>

                <div v-if="data.IsEligible && data.RecentPayment">
                    <router-link :to="paymentsLink" v-if="paymentsLink">Most recent pension payment</router-link>
                    <p>{{ '$' + data.RecentPayment.Net + ' on ' + data.RecentPayment.CheckDate }}</p>
                </div>
            </div>

            <div v-if="data.IsEligible">
                <h5>Pension Check Destination</h5>
                <p>{{ data.CheckDestination }}</p>
            </div>
        </div>
        </div>
            <div class="d-flex flex-column justify-content-between align-items-center gap-4">
                <router-link v-if="data.IsEligible" :to="`/participant/check-replacement?fund=pension`">Check Replacement Request</router-link>
                <router-link :to="`/participant/pension/estimate-request/`">Estimate Request</router-link>
                <router-link :to="`/participant/pension/pension-application/`">Pension Application</router-link>
                <a href="/documents/fw4p2015.pdf" class="mb-4" target="_blank">Tax Witholding Request Form</a>
            </div>
      </div>
    </div>
  </template>

<script lang="ts">
    import { defineComponent, ref, toRefs, watch, onMounted, computed } from 'vue';
    import { PensionDashboardInfo, PensionPlan } from '@/interfaces/interfaces';

    export default defineComponent({
        props: {
            data: {
                type: Object as () => PensionDashboardInfo,
                required: true
            },

            fromPayment: {
                type: String,
                required: false
            },

            toPayment: {
                type: String,
                required: false
            }
        },

        setup(props) {
            const { data } = toRefs(props);
            const year = ref<number | null>(null);
            const paymentsTo = ref<string | null>(null);
            const paymentsFrom = ref<string | null>(null);

            const hasActivityPlans = computed(() => {
                const plansData = data.value.Plans as Record<string, PensionPlan | undefined>;
                const result: Record<string, PensionPlan> = {};

                for (const key in plansData) {
                    const plan = plansData[key];

                    if (plan && plan.HasActivity) {
                        result[key] = plan;
                    }
                }

                return result;
            });

            const paymentsLink = computed(() => {
                // const checkedDate = props.data?.RecentPayment?.CheckDate;
                if (props.data?.RecentPayment) {
                    const checkedDate = props.data?.RecentPayment?.CheckDate
                    const year = new Date(checkedDate).getFullYear();

                    // Construct the query parameters
                    const from = `01/01/${year}`;
                    const to = `12/31/${year}`;

                    return {
                        path: '/participant/pension/payments',
                        query: { from, to },
                    };
                }

                return null;
            });


            return {
                hasActivityPlans,
                paymentsLink,
            };
        }
    });
</script>