<template>
    <div class="form-group mb-4 d-flex flex-column gap-2">
        <label :for="label">
            <span class="fw-bold">{{ label }}</span>
            <span v-if="index > 1" class="fw-bold">{{ ' ' + index }}</span>
            <span v-if="userQuestion">: {{ userQuestion?.Question }}</span>
        </label>
        <div class="form-group mb-4 d-flex justify-content-center gap-2">
            <div class="form-group d-flex flex-column gap-2 w-50">
                <label for="security_question">Enter New Security Question
                    <span v-if="index > 1">{{ ' ' + index }}</span>
                </label>
                <select v-model="selectedQuestions[index].Question" class="form-control" id="security_question"
                    @change="handleQuestion">
                    <option :value="0">Security Questions</option>
                    <option v-for="question in questions" :key="question?.SecurityQuestionID"
                        :value="question?.SecurityQuestionID">
                        {{ question?.Question }}
                    </option>
                </select>
            </div>
            <div class="form-group d-flex flex-column gap-2 w-50">
                <label for="new_security_answer">Enter New Security Answer
                    <span v-if="index > 1">{{ ' ' + index }}</span>
                </label>
                <input v-model="selectedQuestions[index].Answer" class="form-control" type="text" id="new_security_answer"
                    @input="handleQuestion">
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, Ref, watch } from 'vue';
import { SecurityQuestion } from '@/interfaces/interfaces';
import { SelectedQuestion } from '@/interfaces/employer';

export default defineComponent({
    props: {
        label: String,
        questions: {
            type: Array as PropType<SecurityQuestion[]>,
            required: true
        },
        userQuestion: {
            type: Object as PropType<SecurityQuestion>,
            required: true
        },
        questionId: Number,
        index: {
            type: Number,
            required: true
        },
        resetInputs: Boolean,
    },
    setup(props, { emit }) {
        const selectedQuestions: Ref<SelectedQuestion> = ref({
            [props.index]: { Question: 0, Answer: '', ExistingQuestion: props.questionId || 0 }
        });

        watch(
            () => props.resetInputs,
            (newValue) => {
                if (newValue) {
                    selectedQuestions.value = {
                        [props.index]: { Question: 0, Answer: '', ExistingQuestion: props.questionId || 0 }
                    }
                }
            });

        const handleQuestion = () => {
            emit('handle-question', selectedQuestions.value, props.index);
        };

        return {
            selectedQuestions,
            handleQuestion,
        }
    }
})
</script>