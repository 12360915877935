<template>
    <div class="col-md-3">
        <div class="card annuity-card">
            <div class="card-body d-flex flex-column justify-content-between align-items-center">
                <div class="col w-100">
                    <h3 class="text-center mb-4">Annuity</h3>
                    <div class="d-flex flex-column align-items-center gap-2 mb-4 w-100">
                        <a href="https://www.principal.com/" target="_blank">
                            Principal Balance
                        </a>
                        <div class="d-flex flex-column align-items-start gap-2 w-100">
                            <!-- Display accountBalance if it's loaded -->
                            <span v-if="accountBalance !== null" class="form-control text-center" id="balance">
                                {{ accountBalance }}
                            </span>

                            <!-- Display "Loading..." if accountBalance is not yet loaded -->
                            <span v-else class="form-control text-center" id="balance">
                                Loading...
                            </span>
                            <span class="text-right align-self-end d-none" id="balanceDate"> </span>
                        </div>
                        <div v-if="data.ENABLED" class="d-flex flex-column align-items-center gap-1">
                            <router-link :to="{ name: 'annuity-summary', params: { year: data.LastContribution.Year } }">
                                Most Recent Contribution Received </router-link>
                            <div class="d-flex flex-column align-items-start gap-2 w-100">
                                <span class="form-control text-center">
                                    {{ '$ ' + data.LastContribution.Amount }}
                                </span>
                                <span class="text-right align-self-end">
                                    as of {{ data.LastContribution.FormattedDate }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-center gap-2 mb-4 w-100">
                        <router-link to="/profile"> My Current Age</router-link>
                        <span v-if="data" class="form-control text-center mb-4">{{ data.Age }}</span>
                        <div class="d-flex flex-column align-items-center gap-4 mb-4 w-100">
                            <a href="#"> View Annuity Contribution History </a>
                            <a href="#"> Annuity application </a>
                            <a :href="data.SalaryDeferralCoupon" target="_blank">
                                Salary Deferral Coupon
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { AnnuityDashboardData } from '@/interfaces/interfaces';
import axios from 'axios';
import { routeLocationKey } from 'vue-router';

export default defineComponent({
    props: {
        data: {
            type: Object as () => AnnuityDashboardData,
            required: true
        }
    },

    setup(props) {
        const accountBalance = ref(null);

        onMounted(async () => {
            try {
                const response = await axios.get('api/participant/annuity/balance');
                accountBalance.value = response.data.accountBalance;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        });

        return {
            accountBalance,
        };
    }
});
</script>
