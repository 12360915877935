<template>
  <div class="container mt-4">
    <div class="row">
      <div class="col-md-12 text-center mb-4">
        <h1>Participant Dashboard</h1>
      </div>
    </div>
    <div class="row">
      <HealthPanel v-if="healthData" :data="healthData" />
      <AnnuityPanel v-if="annuityData" :data="annuityData" />
      <PensionPanel v-if="pensionData" :data="pensionData" />
      <VacationPanel v-if="vacationData" :data="vacationData" />
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, defineProps } from 'vue';
import axios from 'axios';
import HealthPanel from '../../components/participant/dashboard/HealthPanel.vue';
import AnnuityPanel from '../../components/participant/dashboard/AnnuityPanel.vue';
import PensionPanel from '../../components/participant/dashboard/PensionPanel.vue';
import VacationPanel from '../../components/participant/dashboard/VacationPanel.vue';

export default {
    setup() {
        const healthData = ref(null);
        const annuityData = ref(null);
        const pensionData = ref(null);
        const vacationData = ref(null);

        const fetchData = () => {
            axios.get('api/participant/dashboard')
            .then(response => {
                healthData.value = response.data.Health;
                annuityData.value = response.data.Annuity;
                pensionData.value = response.data.Pension;
                vacationData.value = response.data.Vacation;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
        };

        onMounted(fetchData);

        return {
            healthData, annuityData, pensionData, vacationData
        };
    },

    components: {
        HealthPanel,
        AnnuityPanel,
        PensionPanel,
        VacationPanel
    }
};
</script>