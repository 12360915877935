import { Ref, onMounted, onBeforeUnmount } from 'vue';

const useClickOutside = (elementRef: Ref<HTMLElement | null>, callback: () => void) => {
  const handleClick = (event: MouseEvent) => {
    const target = event.target as Node;
    if (elementRef.value && !elementRef.value.contains(target)) {
      callback();
    }
  };

  onMounted(() => {
    document.addEventListener('click', handleClick);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClick);
  });
};

export default useClickOutside;
