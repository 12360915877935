<template>
    <div class="container mt-4">
        <div class="row">
            <div class="col-md-12 text-center mb-4">
                <h1>My Dependents</h1>
            </div>
        </div>
        <div v-if="loading" class="text-center">Loading...</div>
        <div v-else class="row">
            <p>
                The displayed information is from your records with the Fund Office. If the information is not correct,
                click
                on the applicable dependent in order to update. If you wish to add a new dependent, click the button on the
                lower right side of your screen.
            </p>

            <DependentsTable :dependents="dependents" :headers="headers" />

            <div class="row d-flex justify-content-between">
                <button class="btn btn-secondary col-md-2"> View My Beneficiaries </button>
                <router-link :to="{ name: 'add-dependent' }" class="btn btn-secondary col-md-2">
                    Add New Dependents
                </router-link>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, Ref, onMounted } from 'vue';
import { Dependent } from '@interfaces/interfaces';
import DependentsTable from '@components/participant/dependents/DependentsTable.vue';
import axios from 'axios';

export default {
    setup() {
        const dependents: Ref<Dependent[] | null> = ref(null);
        const loading = ref(false);
        const headers = [
            'Dependent Name',
            'Relationship',
            'DOB',
            'Gender',
            'Coverage Effective Date',
            'Coverage End Date',
            'Status',
            'Date of Marriage',
            'COBRA',
            'QMSCO',
            'Documents on File'
        ];

        const fetchData = async () => {
            loading.value = true;

            await axios.get('api/participant/dependents')
                .then(response => {
                    dependents.value = response.data;
                    console.log(response.data, 'dependents');
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });

            loading.value = false;
        };

        onMounted(async () => {
            await fetchData();
        });

        return {
            dependents,
            headers,
            loading
        };
    },

    components: {
        DependentsTable
    }
};
</script>