<template>
    <div>
        <div class="impersonation-bar text-center" v-if="isImpersonating">
            <p>You are currently impersonating {{ impersonationName }}. <a href="javascript:;"
                    @click="terminateImpersonation">Stop impersonating</a></p>
        </div>
        <header class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container">
                <a class="navbar-brand" href="#">
                    <h4>I.A.T.S.E National Benefit Funds</h4>
                </a>
                <div class="navbar-nav ml-auto">
                    <div class="container-fluid">
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <router-link v-if="isAuthenticated" :to="{ name: dashboardRoute }" class="nav-link">Main
                                    Dashboard</router-link>
                                <router-link v-if="isAuthenticated && isParticipant" to="/work-history"
                                    class="nav-link">Work
                                    History</router-link>
                                <router-link v-if="isAuthenticated && isParticipant" :to="{ name: 'dependents' }"
                                    class="nav-link">
                                    Beneficiary/Dependents Info
                                </router-link>
                                <router-link v-if="isAuthenticated" :to="{ name: 'contact' }" class="nav-link">Contact
                                    Us</router-link>
                                <router-link v-else :to="{ name: 'contact-public' }" class="nav-link">Contact
                                    Us</router-link>
                            </ul>
                            <div class="nav-item dropdown" v-if="isAuthenticated">
                                <a class="nav-link dropdown-toggle nav-width" href="#" id="userDropdown" role="button"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ impersonationName || userName }}
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="userDropdown">
                                    <li v-if="isParticipant"><router-link to="/profile"
                                            class="dropdown-item">Profile</router-link>
                                    </li>
                                    <li v-if="!isAdmin"><router-link to="/settings"
                                            class="dropdown-item">Settings</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/participant/alerts/archived" class="dropdown-item">
                                            Archive
                                        </router-link>
                                    </li>
                                    <li><a href="#" @click="performLogout" class="dropdown-item"
                                            v-if="isAuthenticated">Logout</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="nav-item" v-if="!isAuthenticated">
                                <router-link to="/login" class="nav-link">Login</router-link>
                            </div>
                            <div class="nav-item" v-if="!isAuthenticated">
                                <router-link to="/register" class="nav-link">Register</router-link>
                            </div>
                            <router-link v-if="isAuthenticated" to="/participant/alerts"
                                class="nav-item ms-3 d-flex align-items-center position-relative text-decoration-none">
                                <i class="fa-regular fa-bell text-secondary"
                                    :class="{ 'text-danger': unreadAlerts !== 0 }"></i>
                                <span v-if="unreadAlerts"
                                    class="position-absolute start-100 badge rounded-pill bg-danger badge-size mb-1 counter">
                                    {{ unreadAlerts }} </span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>

<script lang="ts">
import { computed } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useMembersStore } from '@/stores/members'
import { useRouter } from 'vue-router';

export default {
    name: 'HeaderComponent',

    setup() {
        const authStore = useAuthStore();
        const router = useRouter();
        const isImpersonating = computed(() => authStore.isImpersonating);
        const isAuthenticated = computed(() => authStore.isAuthenticated);
        const impersonationName = computed(() => authStore.impersonationName);
        const userName = computed(() => authStore.userName);
        const unreadAlerts = computed(() => authStore.unreadAlerts);
        const isAdmin = computed(() => authStore.userRole === 'ROLE_ADMINISTRATOR');
        const isParticipant = computed(() => authStore.userRole === 'ROLE_PARTICIPANT');

        const dashboardRoute = computed(() => {
            if (isAdmin.value) {
                return isImpersonating.value ? 'participant.dashboard' : 'superadmin.dashboard';
            }
            else if (isParticipant.value) {
                return 'participant.dashboard';
            }
            else if (authStore.isEmployer) {
                return 'employer.dashboard';
            }
            else if (authStore.isLocal) {
                return 'local.dashboard';
            }

            return 'participant.dashboard'
        })

        // Method for performing logout
        const performLogout = () => {
            useMembersStore().setList([]);
            authStore.logout();
        };

        const terminateImpersonation = async () => {
            try {
                // Use the store's action to start impersonation
                await authStore.stopImpersonation();
                router.push({ name: 'superadmin.dashboard' });
                // Optionally, handle any post-login logic, like redirecting the user
            } catch (error) {
                console.error("Impersonation error:", error);
            }
        };

        return {
            isAuthenticated: isAuthenticated,
            isImpersonating: isImpersonating,
            impersonationName: impersonationName,
            userName: userName,
            unreadAlerts,
            isAdmin,
            isParticipant,
            dashboardRoute,
            performLogout,
            terminateImpersonation
        };
    }
}
</script>

<style>
.text-decoration-none {
    text-decoration: none;
}

.counter {
    top: -0.8rem;
}

.impersonation-bar {
    padding: 10px 0;
    background: #ADD8E6;
}

.impersonation-bar p {
    margin: 0;
}

.nav-width {
    display: flex;
    align-items: center;
    min-width: 50px;
}

.nav-width span {
    min-width: 50px;
}
</style>
