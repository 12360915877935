<template>
    <div class="row">
        <h6 class="mb-4"> STEP 2 - Attach Documentation </h6>
        <p class="text-center">
            In order to change or add a dependent you must submit a birth certificate, marriage certificate,
            adoption papers or applicable proof before such dependent can be added.
            Please note: We only support files with sizes below 20MB.
        </p>
        <form id="add_doc_form">
            <div class="dependent-type">
                <div class="form-col">
                    <div v-show="error" class="alert alert-danger">{{ error }}</div>
                    <div v-if="!existingPersonID" class="form-group row d-flex align-items-stretch mb-4" id="document_data">
                        <div class="col-md-6 mb-4">
                            <select v-model="selectedDocType" class="form-control col-md-4 mb-4" id="doc_type">
                                <option value="0">Choose a Document Type</option>
                                <option v-for="docType in documentTypes" :value="docType.TypeID" :key="docType.TypeID">
                                    {{ docType.Description }}</option>
                            </select>
                            <div class="d-flex justify-content-between align-items-end mb-4 gap-4">
                                <div class="col-md-8">
                                    <label>Upload Files</label>
                                    <input type="text" v-model="fileNames" class="form-control" readonly />
                                </div>
                                <div class="col-md-2">
                                    <input type="file" ref="fileInput" @change="handleFileSelection" style="display:none"
                                        multiple />
                                    <button class="btn btn-secondary" @click.prevent="browseFiles" :disabled="loading">
                                        Browse
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="container col-md-6">
                            <div class="upload_box" @dragenter.prevent="preventDefault" @dragover.prevent="preventDefault"
                                @dragleave.prevent="preventDefault" @drop.prevent="handleFileSelection">
                                <h4>Or Drag Files Here</h4>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <p>Documents can be uploaded after saving</p>
                    </div>

                    <div class="border border-secondary p-4 mb-4">
                        <h6 class="text-center mb-4">DOCUMENTS CURRENTLY ON FILE/PENDING ADMIN PREVIEW</h6>
                        <table class="table mb-4" id="documents_table" v-show="uploadedDocs.length > 0">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody v-show="uploadedDocs.length >= 0">
                                <tr v-for="document in uploadedDocs" :key="document.DocumentID">
                                    <td>
                                        <router-link :to="{ name: 'smartsearch.file.view', params: { documentId: document.DocumentID } }">
                                            {{ document.DocumentDescription }}
                                        </router-link>
                                    </td>
                                    <td>{{ new Date(document.Date).toLocaleDateString() }}</td>
                                    <td :class="{ 'text-danger': document.Status === 'Pending' }">{{ document.Status }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="text-center mt-4" id="no_docs" v-show="uploadedDocs.length === 0">
                            No documents have been uploaded for this dependent.
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, PropType } from 'vue';
import { Document, DocumentType } from '@/interfaces/interfaces';
import axios from 'axios';

export default defineComponent({
    props: {
        documentTypes: {
            type: Array as PropType<DocumentType[]>,
            required: true
        },
    },
    setup(props, { emit }: { emit: (event: string, ...args: any[]) => void }) {
        const selectedDocType = ref('0');
        const uploadedDocs: Ref<Document[]> = ref([]);
        const fileNames = ref('');
        const fileInput = ref<HTMLInputElement | null>(null);
        const formData = new FormData();
        const error: Ref<string | null> = ref(null);
        const loading = ref(false);

        const preventDefault = (ev: Event) => {
            ev.preventDefault();
        }

        const browseFiles = () => {
            fileInput.value?.click();
        }

        const handleSubmit = () => {
            emit('submit-data');
        }

        const handleStep1 = () => {
            emit("check-data", false);
        }

        const handleFileSelection = async (event: Event) => {
            loading.value = true;
            const files = (event.target as HTMLInputElement)?.files || (event as DragEvent)?.dataTransfer?.files;
            console.log(files);
            formData.append('DocumentType', selectedDocType.value);

            if (selectedDocType.value === '0' || !selectedDocType.value) {
                error.value = 'The selected document type is invalid.';
                loading.value = false;

                setTimeout(() => {
                    error.value = '';
                }, 3000);

                return;
            }

            if (files) {
                await uploadFiles(files);
            }

            fileNames.value = '';
            loading.value = false;
        };

        // Upload Files in Storage
        const uploadFiles = async (files: FileList) => {
            for (let i = 0; i < files.length; i++) {
                formData.append('Files[]', files[i]);
                fileNames.value += files[i].name;
            }

            await axios.post('api/smartsearch/personal-file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(response => {
                    uploadedDocs.value = response.data.Files;
                })
                .catch(error => {
                    console.error('There was an error uploading document!', error);
                });
        }

        return {
            existingPersonID: false,
            selectedDocType,
            fileNames,
            uploadedDocs,
            preventDefault,
            browseFiles,
            handleFileSelection,
            handleSubmit,
            handleStep1,
            fileInput,
            error,
            loading
        };
    },
});
</script>

<style scoped>
.upload_box {
    border: 2px dashed #ccc;
    padding: 40px;
    text-align: center;
    cursor: pointer;
}
</style>