<template>
    <div v-if="dependents?.length === 0 || !dependents" class="col-md-12 text-center my-4">
        <span> Currently there are no dependents on file </span>
    </div>
    <table v-else class="table table-bordered table-striped" id="detail_table" style="max-height: 300px">
        <thead class="table-dark">
            <tr>
                <th v-for="(header, key) in headers" :key="key">
                    {{ header }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(dependent, key) in dependents" :key="key" :dependent="dependent">
                <td>{{ dependent.DependentName }}
                    <span :class="{ 'text-danger': dependent.NoSSN !== '' }">
                        {{ dependent.NoSSN }}
                    </span>
                </td>
                <td>{{ dependent.Relationship }}</td>
                <td>{{ dependent.Person.DateofBirth }}</td>
                <td>{{ dependent.Person.Gender }}</td>
                <td>{{ dependent.CoverageEffectiveDate }}</td>
                <td>{{ dependent.CoverageEndDate }}</td>
                <td :class="{ 'text-danger': dependent.Status === 'Pending' }">
                    {{ dependent.Status }}
                </td>
                <td>{{ dependent.Person.MarriageDate }}</td>
                <td>{{ dependent.COBRA }}</td>
                <td>{{ dependent.QMSCO }}</td>
                <td class="text-center">
                    <router-link
                        :to="{ name: 'dependent-documents', query: { PartnerID: dependent.PartnerID, DependentID: dependent.DependentID, Status: dependent.Status } }">
                        <i class="fas fa-paperclip"> </i>
                    </router-link>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Dependent } from '@/interfaces/interfaces';

export default {
    props: {
        dependents: {
            type: Array as PropType<Dependent[] | null>,
            required: true,
        },
        headers: {
            type: Array as PropType<string[]>,
            required: true,
        },
    },
};
</script>