<template>
    <div>
        <h3>Results</h3>

        <div v-for="(yearDetail, year) in data.Years" :key="year">
            <h3>{{ year }}</h3>
            <table class="main-row">
                <tbody>
                    <div v-for="(production, productionName) in yearDetail.Productions" :key="productionName">
                        <tr  data-toggle="collapse" class="main-tr">
                            <td>-</td>
                            <td>{{ production.Employer }}</td>
                            <td>{{ production.WorkPeriod }}</td>
                            <td>-</td>
                            <td>
                                <table>
                                    <tr>
                                        <td>Welfare Plan C</td>
                                        <td>{{ production.Totals[4] }}</td>
                                    </tr>
                                    <tr>
                                        <td>Annuity</td>
                                        <td>{{ production.Totals[5] }}</td>
                                    </tr>
                                    <tr>
                                        <td>Salary Deferral</td>
                                        <td>{{ production.Totals[8] }}</td>
                                    </tr>
                                    <tr>
                                        <td>Pension Plan B</td>
                                        <td>{{ production.Totals[1] }}</td>
                                    </tr>
                                    <tr>
                                        <td>Vacation</td>
                                        <td>{{ production.Totals[6] }}</td>
                                    </tr>
                                </table>
                            </td>
                            <td>+</td>
                        </tr>

                        <!-- Details -->
                        <!-- <tr class="collapse details-tr"> -->
                        <tr class="details-tr">
                            <td colspan="6">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Work Period</th>
                                            <th>Receipt Date</th>
                                            <th>Days Reported</th>
                                            <th>&emsp;</th>
                                            <th>Fund</th>
                                            <th>Contributions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="result in production.Results" :key="result.ParticipantWHID">
                                            <td>{{ result.WorkPeriod }}</td>
                                            <td>{{ result.Employer }}</td>
                                            <td>{{ result.DaysWorked }}</td>
                                            <td><span data-toggle="tooltip" data-html="true" title="D: {{ result.DaysWorked }} <br/> H: {{ result.HoursWorked }}<br/> W: {{ result.EarningsReported }}<br/> CW: {{ result.ContactWages }}">Info</span></td>
                                            <td>{{ result.Plan }}</td>
                                            <td>{{ result.ContributionsPaid }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </div>
                </tbody>
            </table>
        </div>
    </div>
  </template>

  <script lang="ts">
  import { defineComponent, onMounted, PropType } from 'vue';
  import { WorkHistoryData } from '@/interfaces/interfaces';

  export default defineComponent({
    props: {
    data: {
        type: Object as PropType<WorkHistoryData>,
        required: true
        }
    }
  });
  </script>