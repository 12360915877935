<template>
  <div class="container mt-4">
    <div class="row mb-4">
      <div class="col-md-12 text-center">
        <h1> Update Personal Information</h1>
      </div>
    </div>
    <div class="row mb-4">
      <span>
        Enter the information you wish to update in the applicable field. Any changes and/or accompanying
        documentation will be reviewed by our administrative team prior to being made permanent. Note that all
        fields marked with an asterisk are required fields.
      </span>
    </div>
    <div class="row mb-4">
      <div class="col-md-12">
        <h3>STEP 1 - Participant General Information</h3>
      </div>
      <div class="col-md-12 mb-4">
        <div  v-if="currentStep === 1">
            <UpdateInfoStep1 />
        </div>
        <div v-else>
            <UpdateInfoStep2 :documentTypes="documentTypes" />
        </div>
      </div>
        <div class="d-flex justify-content-between">
            <div class="col-md-6">
                <button v-if="currentStep == 2" type="button" class="btn btn-secondary" @click="goToPreviousStep"> Back </button>
            </div>
            <div class="col-md-6 text-right">
                <button v-if="currentStep == 2" type="button" class="btn btn-secondary" :disabled="loading" @click="handleSubmit">
                    {{ loading ? 'Uploading...' : 'Submit' }}
                </button>
                <button v-else type="button" @click="goToNextStep" class="btn btn-secondary">Step 2</button>
            </div>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, reactive, onMounted, Ref } from 'vue';
import useClickOutside from '@composable/useClickOutside';
import useFormSubmit from '@composable/useFormSubmit';
import { Person, MiscellaneousCode, Gender, Local } from '@/interfaces/interfaces';
import axios from 'axios';
import { useRouter } from 'vue-router';
import UpdateInfoStep1 from "@components/profile/personal-info/UpdateInfoStep1.vue";
import UpdateInfoStep2 from "@components/profile/personal-info/UpdateInfoStep2.vue";

export default {
  setup() {
    const initialData = ref<Person | null>(null);
    const showResults = ref(false);
    const locals = ref<Local[]>([]);
    const salutations = ref<MiscellaneousCode[]>([]);
    const maritalStatuses = ref<MiscellaneousCode[]>([]);
    const nameSuffixes = ref<MiscellaneousCode[]>([]);
    const documentTypes: Ref<DocumentType[] | null> = ref(null);
    const genders = ref<Gender[]>([]);
    const searchContainer = ref(null);

    const formData = reactive({ ...initialData.value });

    const route = 'api/user/profile/my-personal-information';
    const router = useRouter();
    const changes = ref(null);
    const errors = ref<string[]>([]);
    const success = ref('');
    const info = ref('');

    const currentStep = ref<number> (1);
    const title: Ref<string> = ref('');
    const isReview = ref(false);
    const loading = ref(true);
    const loadingSearch = ref(false);
    const loadingSubmit = ref(false);

    // Handle Form Submit
    const submitData = async () => {
        const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit });
        await handleSubmit();

        await fetchData();
    }

    // Fetch Initial Data
    const fetchData = async () => {
        try {
            loading.value = true;
            const response = await axios.get(route);
            initialData.value = response.data?.Person;
            maritalStatuses.value = response.data?.MaritalStatuses;
            nameSuffixes.value = response.data?.NameSuffixes;
            salutations.value = response.data?.Salutations;
            genders.value = response.data?.Genders;
            changes.value = response.data?.Changes;
            documentTypes.value = response.data.DocumentTypes;

            Object.assign(formData, initialData.value);
            setDefaultValues();
            loading.value = false;
        } catch (error) {
            console.error('Error fetching personal info:', error);
        }
    };

    const goToNextStep = () => {
        currentStep.value++;
    };

    const goToPreviousStep = () => {
        currentStep.value--;
    };

    // Set Dropdown Default Values
    const setDefaultValues = () => {
        if (!formData.Salutation) {
            formData.Salutation = salutations.value[0].CodeID;
        }

        if (!formData.NameSuffix) {
            formData.NameSuffix = 0;
        }

        if (!formData.MaritalStatus) {
            formData.MaritalStatus = maritalStatuses.value[0].CodeID;
        }
    };

    onMounted(async () => {
        await fetchData();
    });

    return {
        documentTypes,
        currentStep,
        loading,
        isReview,
        title,
        success,
        goToPreviousStep,
        goToNextStep,
        submitData
    };
  },
  components: {
    UpdateInfoStep1,
    UpdateInfoStep2
  }
}
</script>
