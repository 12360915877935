<template>
    <div class="d-flex justify-content-between align-items-end gap-4 w-100">
        <div class="d-flex flex-column align-items-start gap-4 justify-content-between">
            <span class="fw-bold">{{ title }}</span>
            <span v-if="count"> [there are {{ count }} pending review]</span>
        </div>
        <router-link v-if="status" :to="{ name: route, params: { status: status } }" class="btn btn-secondary w-50 h-25">
            {{ button }}
        </router-link>
        <router-link v-else :to="{ name: route }" class="btn btn-secondary w-50 h-25">
            {{ button }}
        </router-link>
    </div>
</template>

<script lang="ts">

export default {
    props: {
        title: String,
        count: String,
        route: String,
        button: String,
        status: {
            type: String,
            default: null,
        },
    }
}
</script>
