import { defineStore } from "pinia";
import axios from "axios";
import router from "@/router";

interface Credentials {
    email: string;
    password: string;
}

interface User {
    name: string;
    roleName: string;
    unreadAlerts: number;
    email: string;
}

export const useAuthStore = defineStore({
    id: "auth",

    // state: () => ({
    //   token: localStorage.getItem('authToken') as string | null,
    //   user: null as User | null
    // }),

    state: () => {
        return {
            token: localStorage.getItem("authToken") as string | null,
            isImpersonating: false,
            impersonationToken: null,
            impersonationName: "",
            user: JSON.parse(
                localStorage.getItem("user") || "null"
            ) as User | null,
        };
    },

    getters: {
        isAuthenticated: (state) => {
            return !!state.token;
        },
        isEmployer: (state) => {
            const employerRoles = [
                "ROLE_EMPLOYER",
                "ROLE_EMPLOYER_PAYOR",
                "ROLE_EMPLOYER_ADMIN",
            ];

            if (state.user) {
                return employerRoles.includes(state.user.roleName);
            }
        },
        isLocal: (state) => {
            const localRoles = ['ROLE_LOCAL', 'ROLE_LOCAL_ADMIN'];

            if(state.user) {
                return localRoles.includes(state.user.roleName);
            }
        },
        isLocalAdmin: (state) => {
            return state.user && state.user.roleName === 'ROLE_LOCAL_ADMIN';
        },
        userName: (state) => state.user?.name,
        userEmail: (state) => state.user?.email,
        userRole: (state) => state.user?.roleName,
        unreadAlerts: (state) => state.user?.unreadAlerts,
    },

    actions: {
        async getCsrfCookie() {
            try {
                // Get CSRF cookie from Laravel Sanctum
                await axios.get("sanctum/csrf-cookie");
            } catch (error) {
                console.error("Error getting CSRF cookie:", error);
            }
        },

        async authenticateUser(credentials: Credentials) {
            this.getCsrfCookie()
                .then(() => {
                    axios
                        .post("api/auth", credentials)
                        .then((response) => {
                            this.setToken(response.data.token);
                            this.setUser({
                                name: response.data.name,
                                roleName: response.data.role,
                                unreadAlerts: response.data.unreadAlerts,
                                email: response.data.email,
                            });

                            // If login is successful, you can redirect the user here
                            let dashboard = "participant.dashboard";

                            if (response.data.role == "ROLE_ADMINISTRATOR") {
                                dashboard = "superadmin.dashboard";
                            }

                            if (this.isEmployer) {
                                dashboard = "employer.dashboard";
                            }

                            if (this.isLocal) {
                                dashboard = "local.dashboard";
                            }

                            router.push({ name: dashboard });
                        })
                        .catch((error) => {
                            // Handle failed login attempt
                            if (
                                error.response &&
                                error.response.status === 401
                            ) {
                                // Here you can handle a 401 Unauthorized response, and return an appropriate message
                                console.error("Invalid credentials");
                                alert("401 Invalid credentials"); // Adjust based on how you want to communicate this to the user
                            } else {
                                alert(error.response.data.message);
                            }
                        });
            })
            .catch(error => {
                // Handle error in getting CSRF cookie
                console.error("Error setting CSRF cookie:", error);
            });
        },

        updateUnreadAlerts(newCount: number) {
            if (this.user) {
                this.user.unreadAlerts = newCount;
            }
        },

        setToken(token: string | null) {
            this.token = token;
            if (token) {
                localStorage.setItem("authToken", token);
            } else {
                localStorage.removeItem("authToken");
            }
        },

        // @old
        // setUser(name: string, roleName: string) {
        //   // Using $patch to ensure reactivity
        //   this.$patch({
        //     user: { name, roleName }
        //   });
        // },

        setUser(newUser: User) {
            this.user = newUser;
            localStorage.setItem("user", JSON.stringify(newUser));
        },

        clearUser() {
            this.user = null;
            localStorage.removeItem("user");
        },

        async startImpersonation(userID: number) {
            try {
                const response = await axios.post(
                    "/api/administrator/impersonate/start",
                    {
                        UserID: userID,
                    }
                );
                this.impersonationToken = response.data.token;
                this.isImpersonating = true;
                this.impersonationName = response.data.name;
            } catch (error) {
                console.log("++ impersonate error ++" + error);
                throw error;
            }
        },

        async stopImpersonation() {
            try {
                const response = await axios.get(
                    "api/administrator/impersonate/stop"
                );
                this.impersonationToken = null;
                this.isImpersonating = false;
                this.impersonationName = "";
            } catch (error) {
                console.log("++ impersonate stop error ++" + error);
                throw error;
            }
        },

        logout() {
            this.setToken(null);
            this.$reset();

            router.push({ name: "login" });
        },
    },
});
