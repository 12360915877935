<template>
    <div v-if="loading">Loading Form...</div>
    <form v-else @submit.prevent="submitData">
        <div v-if="success" class="alert alert-success">
            {{ success }}
        </div>
        <div v-if="info" class="alert alert-info">
            {{ info }}
        </div>
        <div v-if="errors.length > 0" class="alert alert-danger">
            <ul>
                <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
        </div>
        <div class="form-group row mb-4">
            <div class="col-md-3">
                <label for="participant_id" class="col-form-label text-md-right">Participant ID</label>
                <input v-model="formData.PersonID" type="text" id="participant_id" :class="{
                    'form-control': true, 'border-danger': changes.PersonID
                }" readonly>
            </div>
            <div class="col-md-3">
                <label for="social_security" class="col-form-label text-md-right"> Social Security #
                </label>
                <a href="#" class="form-control btn btn-outline-secondary" id="show_ssn">Show SSN</a>
            </div>
            <div class="col-md-6">
                <label for="salutation" class="col-form-label text-md-right">Salutation</label>
                <select v-model="formData.Salutation" :class="{
                    'form-control': true, 'border-danger': changes.Salutation
                }" id="salutation" name="Salutation">
                    <option v-for="salutation in salutations" :value="salutation?.CodeID" :key="salutation?.CodeID">
                        {{ salutation?.Description }}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row mb-4">
            <div class="col-md-6">
                <label for="first_name" class="col-form-label text-md-right">First Name <span
                        class="text-danger">*</span></label>
                <input type="text" id="first_name" name="FirstName" maxlength="12" v-model="formData.FirstName" :class="{
                    'form-control': true, 'border-danger': changes.FirstName
                }" required>
            </div>
            <div class="col-md-6">
                <label for="middle_name" class="col-form-label text-md-right">Middle Name</label>
                <input type="text" id="middle_name" name="MiddleName" maxlength="12" v-model="formData.MiddleName" :class="{
                    'form-control': true, 'border-danger': changes.MiddleName
                }">
            </div>
        </div>

        <div class="form-group row mb-4">
            <div class="col-md-6">
                <label for="last_name" class="col-form-label text-md-right">Last Name <span
                        class="text-danger">*</span></label>
                <input type="text" id="last_name" name="LastName" maxlength="25" v-model="formData.LastName" :class="{
                    'form-control': true, 'border-danger': changes.LastName
                }" required>
            </div>
            <div class="col-md-6">
                <label for="suffix" class="col-form-label text-md-right">Suffix</label>
                <select v-model="formData.NameSuffix" :class="{
                    'form-control': true, 'border-danger': changes.NameSuffix
                }" id="suffix" name="NameSuffix">
                    <option value="0">Select Name Suffix</option>
                    <option v-for="nameSuffix in nameSuffixes" :value="nameSuffix?.CodeID" :key="nameSuffix?.CodeID">
                        {{ nameSuffix?.Description }}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row mb-4">
            <div class="col-md-6">
                <label for="date_of_birth" class="col-form-label text-md-right">Date of Birth <span
                        class="text-danger">*</span></label>
                <input type="date" id="date_of_birth" name="DateofBirth" v-model="formData.DateofBirth" :class="{
                    'form-control': true, 'border-danger': changes.DateofBirth
                }" required>
            </div>
            <div class="col-md-6">
                <label for="gender" class="col-form-label text-md-right">Gender</label>
                <select v-model="formData.Sex" :class="{
                    'form-control': true, 'border-danger': changes.Sex
                }" id="gender" name="Sex">
                    <option v-for="(value, key) in genders" :value="key" :key="key">
                        {{ value }}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row mb-4">
            <div class="col-md-6">
                <label for="marital_status" class="col-form-label text-md-right">Marital Status</label>
                <select v-model="formData.MaritalStatus" :class="{
                    'form-control': true, 'border-danger': changes.MaritalStatus
                }" id="suffix" name="MaritalStatus">
                    <option v-for="maritalStatus in maritalStatuses" :value="maritalStatus?.CodeID"
                        :key="maritalStatus?.CodeID">
                        {{ maritalStatus?.Description }}
                    </option>
                </select>
            </div>
            <div class="col-md-6">
                <label for="marital_status" class="col-form-label text-md-right">Home Local</label>
                <input v-model="formData.HomeLocal" @input="handleSearch" :class="{
                    'form-control': true, 'border-danger': changes.HomeLocal
                }" type="search" placeholder="Enter at least 2 characters to search" />
                <ul v-if="loadingSearch" class="list-group">
                    <li class="list-group-item">Searching... </li>
                </ul>
                <ul v-else v-show="showResults" class="list-group" ref="searchContainer">
                    <li v-for="local in locals" :key="local.LocalID" class="list-group-item"
                        @click="selectLocal(local.OrgName)">
                        {{ local.OrgName }}
                    </li>
                </ul>
            </div>
        </div>
    </form>
</template>


<script lang="ts">
import { ref, reactive, onMounted } from 'vue';
import { Person, MiscellaneousCode, Gender, Local } from '@/interfaces/interfaces';
import useClickOutside from '@composable/useClickOutside';
import useFormSubmit from '@composable/useFormSubmit';
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {
    setup() {
        const loading = ref(true);
        const loadingSearch = ref(false);
        const loadingSubmit = ref(false);
        const initialData = ref<Person | null>(null);
        const formData = reactive({ ...initialData.value });
        const showResults = ref(false);
        const locals = ref<Local[]>([]);
        const salutations = ref<MiscellaneousCode[]>([]);
        const maritalStatuses = ref<MiscellaneousCode[]>([]);
        const nameSuffixes = ref<MiscellaneousCode[]>([]);
        const genders = ref<Gender[]>([]);
        const searchContainer = ref(null);
        const changes = ref(null);
        const errors = ref<string[]>([]);
        const success = ref('');
        const info = ref('');
        const route = 'api/user/profile/my-personal-information';
        const router = useRouter();

        // Handle Form Submit
        const submitData = async () => {
            const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit });
            await handleSubmit();

            await fetchData();
        }

        useClickOutside(searchContainer, () => {
            showResults.value = false;
        });

        // Handle Search Locals
        const searchLocals = async (searchedLocal: string) => {
            try {
                const response = await axios.get('/api/user/locals', {
                    params: {
                        query: searchedLocal,
                    },
                });

                locals.value = response.data;
            } catch (error) {
                console.error('Error searching locals:', error);
            }
        }

        // Set Dropdown Default Values
        const setDefaultValues = () => {
            if (!formData.Salutation) {
                formData.Salutation = salutations.value[0].CodeID;
            }

            if (!formData.NameSuffix) {
                formData.NameSuffix = 0;
            }

            if (!formData.MaritalStatus) {
                formData.MaritalStatus = maritalStatuses.value[0].CodeID;
            }
        };

        // Handle Locals Search
        const handleSearch = async (e: any) => {
            let searchedText = (e.target as HTMLInputElement).value;
            locals.value = [];
            showResults.value = false;
            loadingSearch.value = true;

            if (searchedText.length > 1) {
                await searchLocals(searchedText);
            }

            if (locals.value.length > 0) {
                showResults.value = true;
                loadingSearch.value = false;
            } else {
                showResults.value = false;
                locals.value = [];
            }
        };

        const selectLocal = (local: string) => {
            formData.HomeLocal = local;
            showResults.value = false;
        };

        // Fetch Initial Data
        const fetchData = async () => {
            try {
                loading.value = true;
                const response = await axios.get(route);
                initialData.value = response.data?.Person;
                maritalStatuses.value = response.data?.MaritalStatuses;
                nameSuffixes.value = response.data?.NameSuffixes;
                salutations.value = response.data?.Salutations;
                genders.value = response.data?.Genders;
                changes.value = response.data?.Changes;

                Object.assign(formData, initialData.value);
                setDefaultValues();
                loading.value = false;
            } catch (error) {
                console.error('Error fetching personal info:', error);
            }

        };

        onMounted(async () => {
            await fetchData();
        });


        return {
            formData,
            locals,
            maritalStatuses,
            nameSuffixes,
            salutations,
            genders,
            changes,
            loading,
            loadingSearch,
            loadingSubmit,
            showResults,
            searchContainer,
            success,
            errors,
            info,
            submitData,
            handleSearch,
            selectLocal
        };
    },
}
</script>