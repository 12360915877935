<template>
    <div class="col-md-3" v-if="data.ENABLED">
        <div class="card">
            <div class="card-body d-flex flex-column justify-content-between align-items-center gap-4">
                <div class="col">
                    <h3 class="text-center mb-4">Vacation</h3>

                    <div class="d-flex flex-column align-items-center gap-4 mb-4" v-if="data.TotalContributions.Total > 0">
                        <a href="" class="text-left">
                            Reporting Period:</a>
                        <div class="d-flex justify-content-between align-items-center gap-4">
                            <span class="form-control">
                                {{ data.ReportingYear.From }}
                            </span>
                            <span> - </span>
                            <span class="form-control">
                                {{ data.ReportingYear.To }}
                            </span>
                        </div>
                        <label>Contributions Received:</label>
                        <span class="form-control text-center">
                            {{ data.TotalContributions.Total }}]
                        </span>
                    </div>
                    <div class="d-flex flex-column align-items-center gap-4 mb-4"
                        v-else-if="data.TotalPrevContributions.Total > 0">
                        <div class="d-flex align-items-center gap-1">
                            <label v-if="!data.CheckInfo.CurrentCheck"> Contributions for </label>
                            <label v-else>Payment for</label>
                            <router-link :to="{ name: 'participant.vacation.summary' }">{{ data.TotalPrevContributions.Year
                            }}</router-link>
                        </div>
                        <span class="form-control text-center">
                            {{ '$ ' + data.TotalPrevContributions.Total }}
                        </span>
                    </div>
                    <div class="d-flex flex-column align-items-center gap-4 mb-4" v-else>
                        <a href="">
                            Last Reporting Period:</a>
                        <div class="d-flex justify-content-between align-items-center gap-4">
                            <span class="form-control">
                                {{ data.ReportingYear.From }}
                            </span>
                            <span> - </span>
                            <span class="form-control">
                                {{ data.ReportingYear.To }}
                            </span>
                        </div>
                        <label>Contributions Received:</label>
                        <span class="form-control text-center">
                            {{ data.LastContribution.Amount }}
                        </span>
                    </div>

                    <div class="d-flex flex-column align-items-center gap-4">
                        <h6> Vacation Check Destination: </h6>
                        <span class="form-control text-center">
                            {{ data.VacationCheckDestination }}
                        </span>
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-between align-items-center gap-4">
                    <a href="#">
                        View Vacation Contribution History</a>
                    <router-link :to="`/participant/vacation/benefits-history/`">View History of Benefits Paid</router-link>
                    <router-link :to="`/participant/check-replacement?fund=vacation`">Check Replacement Request</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VacationDashboardInfo } from '@/interfaces/interfaces';

export default defineComponent({
    props: {
        data: {
            type: Object as () => VacationDashboardInfo,
            required: true
        }
    }
});
</script>