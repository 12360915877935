<template>
    <div>
        <form class="work-history-filters" @submit.prevent="submitFilters">
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <label for="period">Period</label>
                    <select v-model="filters.period" name="period" id="period" class="form-control">
                        <option value="current_year">Current Year</option>
                        <option value="previous_year">Previous Year</option>
                        <option value="custom_range">Custom Range (2 years max)</option>
                    </select>
                    <br>
                    <div class="row" id="dateFields" style="display: none;">
                        <div class="col-md-6">
                            <input v-model="filters.from_date" type="date" id="fromDate" name="from_date" class="form-control">
                        </div>
                        <div class="col-md-6">
                            <input v-model="filters.to_date" type="date" id="toDate" name="to_date" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <label for="employers">Employers</label>
                    <multiselect
                        v-model="selectedEmployers"
                        :options="employersOptions"
                        :multiple="true"
                        track-by="OrganizationID"
                        label="OrgName"
                        placeholder="Select Employers"
                        @search-change="searchEmployers"
                        @select="updateEmployerSelection"
                    ></multiselect>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <label for="productions[]">Productions</label>
                    <multiselect
                        v-model="selectedProductions"
                        :options="productionsOptions"
                        :multiple="true"
                        track-by="ProductionID"
                        label="OrgName"
                        placeholder="Select Productions"
                        @search-change="searchProductions"
                        @select="updateProductionSelection"
                    ></multiselect>
                </div>
                <div class="col-md-6 col-sm-12">
                    <label for="funds[]">Funds</label>
                    <multiselect
                        v-model="selectedFunds"
                        :options="fundsOptions"
                        :multiple="true"
                        track-by="id"
                        label="name"
                        placeholder="Select Funds"
                        @select="updateFundSelection"
                    ></multiselect>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-2 col-sm-12">
                    <button class="search-work-history btn btn-primary">Search</button>
                </div>
            </div>
        </form>
    </div>
</template>
<script lang="ts">
    import { defineComponent, reactive, ref, watch, onMounted } from 'vue';
    import axios from 'axios';
    import Multiselect from 'vue-multiselect';
    import 'vue-multiselect/dist/vue-multiselect.css'; // Ensure this path matches your project structure

    interface Employer {
      OrganizationID: number;
      OrgName: string;
      EmployerID: number;
    }

    interface Production {
        OrganizationID: number;
        OrgName: string;
        ProductionID: number;
    }

    interface Filters {
      period: string;
      from_date: string;
      to_date: string;
      employers: number[];
      productions: number[];
      funds: number[];
    }

    interface Funds {
      [key: string]: string;
    }

    interface FundOption {
      id: number,
      name: string
    }

    export default defineComponent({
      components: { Multiselect },
      setup(_, { emit }) {
        const filters: Filters = reactive({
          period: 'current_year',
          from_date: '',
          to_date: '',
          employers: [],
          productions: [],
          funds: []
        });

        const employersOptions = ref<Employer[]>([]);
        const productionsOptions = ref<Employer[]>([]);
        const selectedEmployers = ref([]);
        const selectedProductios = ref([]);
        const funds = ref<Funds | null>(null)
        const fundsOptions = ref<FundOption[]>([]);
        const selectedFunds = ref([]);

        const searchEmployers = async (searchTerm: string) => {
          if (searchTerm.length >= 3) {
            try {
              const response = await axios.get(`/api/participant/employers?searchTerm=${searchTerm}`);
              employersOptions.value = response.data;
            } catch (error) {
              console.error('Error fetching employers:', error);
            }
          }
        };

        const updateEmployerSelection = (newSelection: Employer) => {
            const orgId = newSelection.EmployerID;

            // Check if the OrganizationID already exists in the filters.employers array
            const index = filters.employers.indexOf(orgId);

            if (index === -1) {
            // If the OrganizationID does not exist, add it to the array
            filters.employers.push(orgId);
            } else {
            // If the OrganizationID exists, remove it from the array
            filters.employers.splice(index, 1);
            }
        }

        const searchProductions = async (searchTerm: string) => {
          if (searchTerm.length >= 3) {
            try {
              const response = await axios.get(`/api/participant/productions?searchTerm=${searchTerm}`);
              productionsOptions.value = response.data;
            } catch (error) {
              console.error('Error fetching productions:', error);
            }
          }
        };

        const updateProductionSelection = (newSelection: Production) => {
            console.log(JSON.stringify(newSelection, null, 2));

            const orgId = newSelection.ProductionID;

            // Check if the OrganizationID already exists in the filters.employers array
            const index = filters.productions.indexOf(orgId);

            if (index === -1) {
            // If the OrganizationID does not exist, add it to the array
            filters.productions.push(orgId);
            } else {
            // If the OrganizationID exists, remove it from the array
            filters.productions.splice(index, 1);
            }
        }

        const updateFundSelection = (newSelection: FundOption) => {
            const id = newSelection.id;

            // Check if the OrganizationID already exists in the filters.employers array
            const index = filters.funds.indexOf(id);

            if (index === -1) {
                filters.funds.push(id);
            } else {
            filters.funds.splice(index, 1);
            }
        }

        // fetchData method definition
        const fetchData = async () => {
            try {
                const response = await axios.get<Funds>('/api/participant/funds');
                const fundsData: Funds = response.data;
                // Transform the funds object into an array of FundOption objects
                fundsOptions.value = Object.entries(fundsData).map(([id, name]) => ({
                  id: Number(id), // Convert string to number
                  name
                }));
            }
            catch (error) {
                console.error('Error fetching fund data:', error);
            }
        };

        onMounted(fetchData);

        const submitFilters = () => {
          emit('update-filters', filters);
        };

        return {
          filters,
          employersOptions,
          selectedEmployers,
          submitFilters,
          searchEmployers,
          updateEmployerSelection,
          productionsOptions,
          selectedProductios,
          searchProductions,
          updateProductionSelection,
          fundsOptions,
          selectedFunds,
          updateFundSelection
        };
      }
    });
</script>