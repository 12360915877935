<template>
    <footer class="footer navbar-light bg-light mt-4 py-3">
        <div class="container">
            <a class="navbar-brand" href="#">
                <h5>I.A.T.S.E National Benefit Funds</h5>
            </a>
            <div class="navbar-nav ml-auto">
                <a class="nav-link" href="#">Terms</a>
                <a class="nav-link" href="#">Privacy</a>
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
    export default {
        name: 'FooterComponent'
    }
</script>