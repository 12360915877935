import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-end gap-4 w-100" }
const _hoisted_2 = { class: "d-flex flex-column align-items-start gap-4 justify-content-between" }
const _hoisted_3 = { class: "fw-bold" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString($props.title), 1),
      ($props.count)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, " [there are " + _toDisplayString($props.count) + " pending review]", 1))
        : _createCommentVNode("", true)
    ]),
    ($props.status)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: { name: $props.route, params: { status: $props.status } },
          class: "btn btn-secondary w-50 h-25"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.button), 1)
          ]),
          _: 1
        }, 8, ["to"]))
      : (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: { name: $props.route },
          class: "btn btn-secondary w-50 h-25"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.button), 1)
          ]),
          _: 1
        }, 8, ["to"]))
  ]))
}